<template>
    <div class="map-page-content">
        <button class="map-button map-button-back" title="Go Back" @click="go_back">&lt;</button>
        <div class="lesson-content">
            <header class="lesson-header">
                <p>Finished: <span>{{ progress }}% of the lesson</span></p>
                <progress v-bind:value="progress" max="100"></progress>
                <div class="lesson-structure" v-if="has_bookmarks" :class="{ 'collapsed': ls_collapsed }">
                    <a :href="'#' + key" v-for="(title, key) in bookmarks" :key="key">{{ title }}</a>
                    <span class="structure-caption">Lesson<br />Structure</span>
                    <span class="lesson-strucutre-collapse" @click="ls_collapsed = !ls_collapsed"></span>
                </div>
            </header>
            <div class="lesson-inner-content">
                <h1 class="lesson-title">{{ lesson.title }}</h1>
                <ul class="map-post-tags">
                    <li v-for="(tag) in lesson.tags" :key="tag">#{{ tag }}</li>
                </ul>
                <!-- <span>Leave a Rating <ratings :stars="stars"></ratings></span> -->
                <!-- <CheckmarkQuiz /> -->
                <div class="lesson-description">
                    <p v-html="lesson.summary"></p>
                </div>
                <div class="lesson-dynamic-content" ref="content" v-html="lesson.content"></div>
            </div>
            <router-link
                :to="'/e-learning/lesson/' + nextLesson.id"
                class="next-lesson-button"
                data-label="Checkout our next lesson"
                v-if="nextLesson.title"
                @click="scroll_to_top"
                >
                {{ nextLesson.title }}
            </router-link>
        </div>
    </div>
</template>

<script>
// import Vue from 'vue';

import { createApp } from 'vue';

import QuestionsQuiz from '@/components/Lessons/Blocks/QuestionsQuiz';

import { Quiz } from '@/plugins/quiz';

import { PostService } from '@/services';

export default {
    name: 'ReadLesson',
    components: {
        // Ratings,
        // LessonsScroll,
        // CheckmarkQuiz,
        // vuescroll,
        // Comments,
    },
    props:
    {
        lesson: Object,
        nextLesson: Object,
    },
    data()
    {
        return {
            loading: false,
            ls_collapsed: false,
            has_bookmarks: false,
            progress: 0,
            bookmarks: {},
            comments: {},
            currentQuestion: {},
            body: '',
            lessons: [],
            stars: 5,
            is_done: false,
        };
    },
    mounted()
    {
        document.querySelector('#app .main-layout > .ps').onscroll = (ev) =>
        {
            const target_el = ev.target;

            const scroll_progress = (target_el.scrollTop + window.innerHeight) / target_el.scrollHeight * 100;

            this.progress = Math.ceil(Math.max(this.progress, Math.min(100, scroll_progress)));

            if (this.progress === 100 && !this.is_done)
            {
                PostService.done(this.lesson.id);

                this.is_done = true;

                // this.lesson.is_done = true;
            }
        };

        setTimeout(() =>
        {
            this.build_bookmarks();
            this.add_check_quiz();
            this.$forceUpdate();

            setTimeout(() =>
            {
                const header_height = (document.querySelector('.lesson-header').offsetHeight || 0) + 32;

                document.documentElement.style.setProperty('--lesson-header-height', header_height + 'px');

                this.loading = false;
            }, 50);

            // if (this.lesson.user_progress.is_done)
            // {
            //     this.progress = 100;
            // }

            Quiz.init_all();
        }, 50);
    },
    beforeUnmount()
    {
        document.querySelector('#app .main-layout > .ps').onscroll = null;
    },
    methods:
    {
        build_bookmarks()
        {
            this.has_bookmarks = false;
            this.bookmarks = {};
            this.$refs.content.querySelectorAll('.lesson-section .section-title').forEach((item) =>
            {
                const title = item.innerText.trim();
                const id = item.getAttribute('id');

                this.bookmarks[id] = title;
                this.has_bookmarks = true;
            });
        },

        add_check_quiz()
        {
            document.querySelectorAll('.check-quiz-block').forEach((el) =>
            {
                let questions = JSON.parse(el.innerText.trim());

                createApp(QuestionsQuiz, {
                    questions: questions || [],
                }).mount(el);
            });
        },

        go_back()
        {
            // this.$router.push({ name: 'CoursePost', params: { id: this.lesson.elearning.id } });
            this.$router.back();
        },

        scroll_to_top()
        {
            setTimeout(() =>
            {
                window.scrollTo(0, 0, 'smooth');
            }, 50);
        },
    }
};
</script>
